<template>
  <div class="property-conditions">
    <b-alert variant="warning" class="content" show>
      <p>
        <strong>Informazioni di sicurezza, sanitarie e doganali:</strong>
      </p>
      <span>
        se stai prenotando in una destinazione estera verifica prima le condizioni e i requisiti di viaggio sul sito <a href="https://www.viaggiaresicuri.it/home" target="_blank"><strong>Viaggiare sicuri all’estero</strong></a> del Governo italiano.
      </span>
    </b-alert>
  </div>
</template>

<script>
export default {
  name: 'property-conditions',
  components:{
  },
  data(){
    return {
    }
  },
  props: {
  },
  computed: {
  }
}
</script>

<style lang="scss">
@import "~bootstrap/scss/functions";
@import './src/scss/_custom.scss';

.property-conditions
{
  *
  {
    font-size:14px;
  }
  strong
  {
    color:$black;
  }
  .link
  {
    color:$light-blue;
    font-weight: 600;
  }
  p
  {
    margin-bottom:0;
  }
  a
  {
    white-space:nowrap;
    font-weight: 500;
    color:$light-blue;
  }
  .btn-chevron-right
  {
    display:inline-flex;
    margin:0 8px;
    svg
    {
      margin-left:4px;
      height: 12px;
      width: auto;
      transform: translateY(1px);
    }
  }
}
</style>