var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "property-details-modal" },
    [
      _vm._l(Object.keys(_vm.titoli), function (field, index) {
        return _c("div", { key: "property-details-modal-field-" + index }, [
          _vm.details[field]
            ? _c("div", [
                _c("h6", { staticClass: "property-detail-title" }, [
                  _vm._v("\n        " + _vm._s(_vm.titoli[field]) + "\n      "),
                ]),
                _c("div", { staticClass: "property-detail-content" }, [
                  _c("p", {
                    domProps: { innerHTML: _vm._s(_vm.details[field]) },
                  }),
                ]),
                _c("br"),
              ])
            : _vm._e(),
        ])
      }),
      _vm.details.attributes.pets
        ? _c(
            "div",
            [
              _c("h6", { staticClass: "property-detail-title" }, [
                _vm._v("Policy animali"),
              ]),
              _vm._l(_vm.details.attributes.pets, function (value, index) {
                return _c("ul", { key: "property-info-" + index }, [
                  _c("li", {
                    staticClass: "property-detail-content",
                    domProps: { innerHTML: _vm._s(value.name) },
                  }),
                ])
              }),
              _c("br"),
            ],
            2
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }