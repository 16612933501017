var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "property-details-modal" }, [
    _vm.details.location
      ? _c("div", [
          _c("h6", { staticClass: "property-detail-title" }, [
            _vm._v("Location"),
          ]),
          _c("p", { staticClass: "property-detail-content" }, [
            _vm._v(_vm._s(_vm.details.location)),
          ]),
          _c("br"),
        ])
      : _vm._e(),
    _vm.details.amenities
      ? _c("div", [
          _c("h6", { staticClass: "property-detail-title" }, [
            _vm._v("Servizi"),
          ]),
          _c("p", { staticClass: "property-detail-content" }, [
            _vm._v(_vm._s(_vm.details.amenities)),
          ]),
          _vm.details.attributes.general
            ? _c(
                "div",
                _vm._l(_vm.details.attributes.general, function (value, index) {
                  return _c("ul", { key: "property-info-" + index }, [
                    _c("li", {
                      staticClass: "property-detail-content",
                      domProps: { innerHTML: _vm._s(value.name) },
                    }),
                  ])
                }),
                0
              )
            : _vm._e(),
          _c("br"),
        ])
      : _vm._e(),
    _vm.details.rooms
      ? _c("div", [
          _c("h6", { staticClass: "property-detail-title" }, [
            _vm._v("Descrizione camere"),
          ]),
          _c("p", { staticClass: "property-detail-content" }, [
            _vm._v(_vm._s(_vm.details.rooms)),
          ]),
          _c("br"),
        ])
      : _vm._e(),
    _vm.details.attractions
      ? _c("div", [
          _c("h6", { staticClass: "property-detail-title" }, [
            _vm._v("Attrazioni nelle vicinanze"),
          ]),
          _c("span", {
            staticClass: "property-detail-content",
            domProps: { innerHTML: _vm._s(_vm.details.attractions) },
          }),
          _c("br"),
        ])
      : _vm._e(),
    _vm.details.fees_optional
      ? _c("div", [
          _c("h6", { staticClass: "property-detail-title" }, [
            _vm._v("Servizi facoltativi"),
          ]),
          _c("span", {
            staticClass: "property-detail-content",
            domProps: { innerHTML: _vm._s(_vm.details.fees_optional) },
          }),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }