var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "property-conditions" },
    [
      _c(
        "b-alert",
        { staticClass: "content", attrs: { variant: "warning", show: "" } },
        [
          _c("p", [
            _c("strong", [
              _vm._v("Informazioni di sicurezza, sanitarie e doganali:"),
            ]),
          ]),
          _c("span", [
            _vm._v(
              "\n      se stai prenotando in una destinazione estera verifica prima le condizioni e i requisiti di viaggio sul sito "
            ),
            _c(
              "a",
              {
                attrs: {
                  href: "https://www.viaggiaresicuri.it/home",
                  target: "_blank",
                },
              },
              [_c("strong", [_vm._v("Viaggiare sicuri all’estero")])]
            ),
            _vm._v(" del Governo italiano.\n    "),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }